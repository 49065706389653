import Block from '../../adapters/helpers/Block';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import BaseContext from '../BaseContext';
import { HTMLConstants } from '../../adapters/helpers/Constants';
import { SeeMoreButtonConstants } from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { scrollIntoView } from '../../adapters/helpers/Scroll';
const Image = dynamic(() => import('../Image/Image'))
const Icon = dynamic(() => import('../Icon/Icon'))
import DOMPurify from 'isomorphic-dompurify';
import Link from 'next/link';

function HTMLBlock(props) {
    const block = new Block(props);
    const id = block.getAnchorId();
    const className = 'ob-html-block ' + block.getFieldValue('classNames');
    const text = block.getFieldValue('text');
    const seeMoreLabel = block.getFieldValue('seeMoreLabel')?.fields?.text;
    const seeLessLabel = block.getFieldValue('seeLessLabel')?.fields?.text;
    const devicesConfig = block.getFieldValue('devicesConfigurations');
    const [seeMoreStatus, setSeeMoreStatus] = useState(false);
    const { isSmall } = props;

    const handleSeeMore = (event) => {
        setSeeMoreStatus(!seeMoreStatus);
    };
    
    useEffect(() =>  {
        let ancherLink = document.getElementById('Dental-CI-Ancher-Link-Block');
        let ancherLinkSec = document.getElementById('Dental-CI-Second-Page-Ancher-Link-Block');
        let linkEle = document.getElementById('ob-accordion-1');
        let secondLink = document.getElementById('Dental-CI-HTML-Second-Link-img');
        let secondLinkSec = document.getElementById('Dental-CI-Second-Page-HTML-Second-Link');
        let mapLink = document.getElementById('ob-map-block');
        ancherLink && ancherLink.addEventListener('click', function () {
            if(linkEle) {
                scrollIntoView(linkEle, {behavior: 'smooth', block: 'start'});
            }
        });
        secondLink && secondLink.addEventListener('click', function () {
            if(mapLink) {
                scrollIntoView(mapLink, {behavior: 'smooth', block: 'start'});
            }
        })

        ancherLinkSec && ancherLinkSec.addEventListener('click', function () {
            if(linkEle) {
                scrollIntoView(linkEle, {behavior: 'smooth', block: 'start'});
            }
        });
        secondLinkSec && secondLinkSec.addEventListener('click', function () {
            if(mapLink) {
                scrollIntoView(mapLink, {behavior: 'smooth', block: 'start'});
            }
        })

    }, []);

    const deviceImgIndex = isSmall ? 1 : 0
    const deviceImg = devicesConfig?.[deviceImgIndex]?.fields?.mainAsset || '';
    const hyperLink = devicesConfig?.[deviceImgIndex]?.fields?.mainAssetLink || '';
    
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
          node.setAttribute('target', '_blank');
          node.setAttribute('rel', 'noopener');
        }
    });

    const htmlContent = <div id={id}
                             className={className}>
                                {!seeMoreLabel && !seeLessLabel && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text, { ADD_TAGS: ["iframe", "a"] },{ ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling',  'ariaLabel', 'style', 'onload', 'target' ] })}}></div> }
                                {seeMoreStatus && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text,{ ADD_TAGS: ["iframe", "a"] },{ ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'ariaLabel', 'style', 'onload', 'target'] })}}></div> }
                                {seeMoreLabel && seeLessLabel && <button aria-label={seeMoreStatus} onClick={(event) => handleSeeMore(event)}>
                                    {seeMoreStatus ? 
                                    <div>
                                         <Icon className="ob-see-more-button__icon" roundedIcon={SeeMoreButtonConstants.blue} name={SeeMoreButtonConstants.chevronLeft}/><span className="ob-see-more-button__text">{seeLessLabel}</span></div> 
                                    : <div>
                                        <Icon className="ob-see-more-button__icon" roundedIcon={SeeMoreButtonConstants.blue} name={SeeMoreButtonConstants.chevronRight}/><span className="ob-see-more-button__text">{seeMoreLabel}</span></div>}
                                    </button>}
                                {deviceImg && hyperLink && hyperLink.hyperLink ? <Link href={hyperLink?.hyperLink} target={hyperLink?.linkType}><Image image={deviceImg} elemId={`${id}-img`} /></Link> : deviceImg ? <Image image={deviceImg} elemId={`${id}-img`} /> : ''}
                        </div>

    return (
        <BaseContext.Consumer>
        { context => context ? id !== HTMLConstants.footerIframe && htmlContent : htmlContent }
        </BaseContext.Consumer>
    )
}

HTMLBlock.propTypes = {
    isSmall: PropTypes.bool,
};

export default mediaQueryHOC(HTMLBlock)